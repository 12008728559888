<template>
    <div class="view pa24">
        <div class="mb20">
            <el-tabs v-model="webType">
                <el-tab-pane label="月租车" name="monthlyRentalCar"></el-tab-pane>
                <el-tab-pane label="临时车" name="temporaryVehicle"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType === 'monthlyRentalCar'">
            <monthly-rental-car-table />
        </div>
        <div v-if="webType === 'temporaryVehicle'">
            <temporary-vehicle-table />
        </div>
    </div>
</template>

<script>
import monthlyRentalCarTable from "./pages/monthlyRentalCarTable";
import temporaryVehicleTable from "./pages/temporaryVehicleTable";
export default {
    name: "chargingStandard",
    components: {
        monthlyRentalCarTable,
        temporaryVehicleTable
    },
    data() {
        return {
            webType: "monthlyRentalCar",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>